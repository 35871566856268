import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollToService {

  private _scrollTo$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _activeRoute$: BehaviorSubject<string> = new BehaviorSubject<string>('home');

  constructor() { }

  get scrollTo$(): Observable<string> {
    return this._scrollTo$.asObservable();
  }

  setScrollTo(route: string): void {
    this._scrollTo$.next(route);
  }

  get activeRoute(): string {
    return this._activeRoute$.getValue();
  }

  get activeRoute$(): Observable<string> {
    return this._activeRoute$.asObservable();
  }

  setActiveRoute(route: string): void {
    this._activeRoute$.next(route);
  }
}
